<template>
  <div class="flex min-h-screen w-full flex-row items-center justify-center bg-blue-50 md:h-screen">
    <div class="hidden h-full w-[28rem] grow md:block md:grow-0">
      <div class="fixed left-0 flex h-screen w-[28rem] items-center justify-center">
        <img
          v-if="sidebarImageUrl"
          :src="sidebarImageUrl"
          :alt="$t('layout.authentication.imageAltText')"
          class="w-full"
        />
      </div>
    </div>
    <div
      class="relative flex w-auto items-center justify-center bg-white p-24 py-80 dark:bg-black sm:p-80 md:h-full md:grow md:p-0"
    >
      <notification-banner />
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const dashboardCustomizationStore = useDashboardCustomization();

const sidebarImageUrl = computed(() => dashboardCustomizationStore.loginSidebarImageUrl);
</script>
